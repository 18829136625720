import React from 'react'
import { css } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FooterIndex from '../components/footerIndex'

const TeamPage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <FooterIndex isSinglePage />
  </Layout>
)

export default TeamPage
